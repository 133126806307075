/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/functions";

@font-face {
    font-family: AGaramondPro-Regular;
    src: url(assets/fonts/AGaramondPro-Regular.otf) format("otf");
  }

  @font-face {
    font-family: AGaramondPro-RegularItalic;
    src: url(assets/fonts/AGaramondPro-Italic.otf) format("otf");
  }
@font-face {
    font-family: AGaramondPro-Bold;
    src: url(assets/fonts/AGaramondPro-Bold.otf) format("otf");
  }

  @font-face {
    font-family: AGaramondPro-BoldItalic;
    src: url(assets/fonts/AGaramondPro-BoldItalic.otf) format("otf");
  }

.jost-400 {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  * {
    box-sizing: border-box;
  }
  
  html, body {
    scroll-behavior: smooth;
  }
:root {
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: rgb(255, 255, 255);
}


:root, html, body {
    font-family: "Jost", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.top-container {
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;

    label {
        font-size: 40px;
        color: white;
        font-weight: 500;
    }
}

.primary-btn {
    background-color: $primary;
    padding: 5px 20px;
    border-radius: 30px;
    color: white;
    cursor: pointer;
}
.primary-btn:hover {
    opacity: 0.7;
}

html, body {
    height: 100%;
}
.spandesign::before {
    content: "";
    border: 8px solid $purple;
    border-radius: 10px;
    margin-right: 10px;
}

.add-card-content {
    height: 100%;
    margin: 5px;
    border-radius: 5px;
    padding: 20px;
    transition: 500ms ;
   border: 2px solid rgb(226, 226, 226);
   display: flex;
   background-color: rgb(212, 212, 212);
   justify-content: center;
   align-items: center;
}
.add-card-content:hover {
    cursor: pointer;
    border: 2px dashed $purple;
}

.upload-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}
.card-content {
    height: 100%;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    transition: 500ms ;
   border: 1px solid rgb(226, 226, 226);
}
.card-content:hover {
    cursor: pointer;
    transition: 500ms ;
    box-shadow:1px 4px 11px 3px rgb(226, 226, 226);
}

.card-content:hover > .card-title {
    color: $purple;
}

.card-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0 !important
}
.padding-0 {
    padding: 0 !important
}
.card-description {
    font-weight: 450;
    color: rgb(116, 115, 115);
    font-size: 0.7rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin: 0 !important;
}
.card-date {
    font-size: 0.9rem;
    color: rgb(116, 115, 115);
}

.card-board {
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-end;
}
html, body {
    height: 100%;
  }

.taskTitle {
    background-color: rgb(248, 248, 248);
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
}

.taskrow {
    background-color: rgb(250, 249, 249);
    margin: 10px;
    height: 80vh;
    
    overflow-y: auto;
    border-radius: 10px;
}
.taskheader {
    margin: 5px 25px;
    border-bottom: 1px  dashed rgb(196, 196, 196);
}

.title {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0 !important
}

.notask {
    color: rgb(116, 115, 115);
    padding: 10px 20px;
}

.borderstyle {
    content: "";
    width: 2rem;
    height: 0.25rem;
    background-color: #e7b008;
}
.plusicon {
    background-color: white;
    padding: 5px;
    width: 27px;
    height: 27px;
    box-shadow:1px 4px 11px 3px rgb(226, 226, 226);
    border-radius: 2%;
}
.plusicon:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 500ms;
}
.modal-labeltitle{
    font-size: 1.3rem;font-weight:500;margin-top: 20px;
}

.deletetask:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}
.draghandle {
    border: 1px dashed $purple;
    opacity: 0.7;
}

@media (width < 1100px){
    .top-container {
        height: 500px;
    }

    .top-container label {
        font-size: 16px;
    }
}

@media (width < 550px) {
    .top-container {
        height: 350px;
    }
    .form-control {
        font-size: 0.8rem !important;
      }
}


.add-btn {
    
    float: right;
    margin: 0 20px;
    padding: 10px 40px;
    border: none;
    background: #000074;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;        
}

.add-btn:hover {
    cursor: pointer;
    opacity: 0.7;
}